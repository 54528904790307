


















import { Component, Prop, Vue } from "vue-property-decorator";
import { Mentor } from "../models";
import MentorTile from "@/components/MentorTile.vue";
import AskQuestionCard from "@/components/AskQuestionCard.vue";
import { Usergroup } from "../models";
import { FilterOptions } from "../models";

@Component({ components: { MentorTile, AskQuestionCard } })
export default class MentorGrid extends Vue {
  @Prop() mentors!: Mentor[];
  @Prop() usergroup!: Usergroup;
  @Prop() filterOptions!: FilterOptions;
}
