







































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Mentor } from "@/models";

import MeerZien from "@/assets/meerzien.svg";
import Reviews from "@/assets/review.svg";
import Sessies from "@/assets/sessies.svg";
import Euro from "@/assets/euro.svg";
import Phone from "@/assets/telefoon-12.svg";

import ContentComponent from "./ContentComponent";
import { mixins } from "vue-class-component";
import { Usergroup } from "../models";
import { FilterOptions } from "../models";
import VueMarkdown from "vue-markdown";

@Component({
  components: { VueMarkdown, MeerZien, Euro, Reviews, Sessies, Phone },
})
export default class MentorCard extends mixins(ContentComponent) {
  @Prop() mentor!: Mentor;
  @Prop() usergroup!: Usergroup;
  @Prop() filterOptions!: FilterOptions;

  get tags() {
    const subjectIds = this.mentor.mentorCompetences.map(m => m.subjectId);
    return this.filterOptions
      ? this.filterOptions.subjects
          .filter(m => subjectIds.includes(m.id))
          .map(m => m.name)
      : [];
  }

  constructor() {
    super();
    this.componentName = "mentor card";
  }

  get mentorUrl() {
    if (this.usergroup) {
      return {
        name: `usergroup-${this.usergroup.usergroupType}-mentor-page-nl`,
        params: {
          usergroupSlug: this.usergroup.slug,
          slug: this.mentor.slug,
          id: this.mentor.id,
        },
      };
    }
    return {
      name: "mentor-page-nl",
      params: { slug: this.mentor.slug, id: this.mentor.id },
    };
  }
}
