























import { Vue, Component } from "vue-property-decorator";

import { namespace } from "vuex-class";
const contentsModule = namespace("contents");
const usergroupModule = namespace("usergroups");
const mentorModule = namespace("mentors");

import { ApiRequest } from "@/store/types";
import {
  UsergroupActionTypes,
  UsergroupGetterTypes,
} from "../store/usergroups/types";
import { Usergroup, UsergroupType, FilterOptions } from "../models";

import MentorTile from "@/components/MentorTile.vue";
import MentorGrid from "@/components/MentorGrid.vue";
import { MentorGetterTypes, MentorActionTypes } from "../store/mentors/types";

@Component({ components: { MentorTile, MentorGrid } })
export default class UsergroupPage extends Vue {
  @usergroupModule.Action(UsergroupActionTypes.LoadUsergroup)
  LoadUsergroup!: ({ usergroupType: UsergroupType, slug: string }) => void;

  @usergroupModule.Getter(UsergroupGetterTypes.Usergroup)
  usergroup!: ApiRequest<Usergroup>;

  @usergroupModule.Action(UsergroupActionTypes.ClearUsergroup)
  ClearUsergroup!: () => void;

  @mentorModule.Getter(MentorGetterTypes.FilterOptions)
  filterOptions!: ApiRequest<FilterOptions>;

  @mentorModule.Action(MentorActionTypes.LoadFilterOptions)
  LoadFilterOptions!: () => void;

  get slug() {
    return this.$route.params.usergroupSlug;
  }

  get usergroupType() {
    return UsergroupType[
      this.$route.meta.usergroupType as keyof typeof UsergroupType
    ];
  }

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    const userGroupPromise = this.LoadUsergroup({
      usergroupType: this.usergroupType,
      slug: this.slug,
    });
    return Promise.all([userGroupPromise, this.LoadFilterOptions()]);
  }

  async mounted() {
    if (!this.usergroup.value) {
      await this.LoadUsergroup({
        usergroupType: this.usergroupType,
        slug: this.slug,
      });
    }
    if (!this.filterOptions.value) {
      await this.LoadFilterOptions();
    }
  }

  beforeDestroy() {
    this.ClearUsergroup();
  }
}
